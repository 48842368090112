import React from "react";

import Editor from "./EditorArticle";
import AutofillEditor from "./AutofillEditor";
import EditorWrapper from "../../EditorWrapper";

import Pot from "../../../redesign/components/PhotoOnTop";
import EmptyArticle from "../../common/EmptyArticle";

const BPhotoOnTop = (props) => {
  var article = EmptyArticle();
  const settings = props.settings;
  const imageUrl = settings["background-image"]
    ? settings["background-image"]
    : "";

  article.title = props.autofill
    ? `Automatyczne wypełnienie: ${settings.autofill_category || "podaj kategorię"
    }`
    : settings.title_normal;
  article.picture.img = settings?.picture?.img; //(!!imageUrl ? window.MEDIA_DOMAIN + '/' + imageUrl.replace('.', ',780,0,0,0.') : '');
  article.url = settings.link;
  article.partner = settings.partner;
  article.label = settings.label;
  article.icon = settings.icon;
  article.rating = settings.rating;
  article.category = {
    ...(settings?.post_category ?? {}),
    name: settings.category
  };
  article.categoryColor = settings.categoryColor;
  article.player = (parseInt(settings.player) === 1);
  article.iconHotNews = (parseInt(settings.iconHotNews) === 1);
  article.hideRating = (parseInt(settings.hideRating) === 1);
  article.stats = (settings.stats ? settings.stats : { shares: '0', comments: '0' });
  article.background = settings.background;

  // autor artykułu
  settings.show_author = props.showAuthor;
  article.author.show = settings.show_author;
  if (settings.show_author) {
    article.author.url = settings.author?.url;
    article.author.name = settings.author_name || settings.author?.name;
    article.author.img = settings.author_img
      ? window.MEDIA_DOMAIN + "/" + settings.author_img.replace(".", ",64,64,0,0.")
      : settings.author?.img;
  }

  // article.hasMobilePcs = props.blockSettings.pcs_background_mobile;
  // article.hasDesktopPcs = props.blockSettings.pcs_background_desktop;

  if (settings.embed) {
    article.embed = settings.embed;
  }

  // props.onRegisterBox(props.blockId, props.id, 'title_normal')

  return (
    <div className={props.classes + " pos-relative"} {...props.events} data-tb-region-item>
      <EditorWrapper
        {...props}
        editor={props.autofill ? AutofillEditor : Editor}
      />
      <Pot
        article={article}
        boxSize={props.size}
        imageHeight={props.imageHeight}
        media={settings.media}
        style={settings.background || props.style}
        withBackground={props.withBackground}
        fetchpriority={props.fetchpriority}
      />
    </div>
  );
};

export default BPhotoOnTop;
