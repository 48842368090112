import React from 'react';
import { getCustomThemeClassName, styled } from "../../stitches.config";
import Block from '../composition-components/Block'
import Adslot from "../Adslot";
import AdslotWithScrollWrapper from "../AdslotWithScrollWrapper";

import creditAgricoleLogo from "../../assets/img/credit_agricole_logo.png"
import creditAgricoleBottomDesktop from "../../assets/img/credit_agricole_bottom_desktop.png"
import creditAgricoleBottomMobile from "../../assets/img/credit_agricole_bottom_mobile.png"
import flagUkraine from "../../assets/img/flag-ukraine.jpg"
import oscarsPCS from "../../assets/img/oscars-PCS.jpg"

import { withThemeContext } from '../../redesign/components/StitchesThemeProvider';

import { SHOW_STATIC_BLOCKS_CONFIG } from './constants/constants';

const BlockList = props => {
    const { adPrefix, adSuffix = '', staticShow = SHOW_STATIC_BLOCKS_CONFIG.default } = props;

    // przeksztalcam strukture blokow, z zachowaniem "połącz z poprzednią sekcją"
    var blocks = [];
    var result = [];
    if (props.settings) {
        props.settings.map((block, i) => {
            var blockSettings = block.settings ? block.settings : {};

            if (blocks.length > 0 && blockSettings.merge === "1") {
                blocks[blocks.length - 1].push(block);
            } else {
                blocks.push([block]);
            }
        });
    }

    var rowNum = 0;
    blocks.map((blockGroup, i) => {
        const sectionNumber = i;

        var blocks = [],
            blockGroupSettings =
                blockGroup[0] && blockGroup[0].settings
                    ? blockGroup[0].settings
                    : {},
            hasBorder = (blockGroupSettings.border_color && blockGroupSettings.border_color.length > 1),
            hasSectionName = false,
            customHotnews = blockGroupSettings.custom_hotnews,
            customHotnewsUkraine = blockGroupSettings.custom_hotnewsUkraine,
            customOscars = blockGroupSettings.custom_oscars;

        const hasBackground = (blockGroupSettings.background_color && blockGroupSettings.background_color.length > 1);
        const blockColorBackground = blockGroupSettings.background_color;
        if (blockGroupSettings.secion_name && blockGroupSettings.secion_name.length > 1) {
            hasSectionName = true;
            blocks.push(<SectionName hasBorder={hasBorder}>{blockGroupSettings.secion_name}</SectionName>);
        }

        blockGroup.map((block, j) => {
            const blockId = rowNum++;
            blocks.push(<Block {...props} id={blockId} key={blockId} settings={block} blockColorBackground={blockColorBackground} blockGroupHasBackground={hasBackground} notLazy={blockId < staticShow} />);
        });

        var breakingNews = null;
        if (blockGroupSettings.hotnews_label && blockGroupSettings.hotnews_label.length > 1) {
            breakingNews = (
                <BreakingNews
                    css={{ "$$background": blockGroupSettings.border_color }}
                >
                    <BreakingType
                        css={blockGroupSettings.hotnews_background_color ? { "background": blockGroupSettings.hotnews_background_color } : {}}
                    >
                        {blockGroupSettings.hotnews_label}
                    </BreakingType>
                    <BreakingBody className="hidden-xs">
                        {blockGroupSettings.hotnews_text}
                    </BreakingBody>
                    <BreakingRadius
                        className="hidden-xs"
                        css={{ "$$background": blockGroupSettings.background_color ? blockGroupSettings.background_color : '#fff' }}
                    />
                </BreakingNews>
            );
        }

        let bottomLogo = null
        if (customHotnews === "1") {
            breakingNews = (
                <CustomBreakingNews
                    css={{ "$$background": "#039597" }}
                >
                    <BreakingType
                        css={{ background: "#ffffff" }}
                    >
                        <CustomHotnewsText><span>#</span>WyzwanieOszczędzanie</CustomHotnewsText>
                    </BreakingType>
                    <img src={creditAgricoleLogo} />
                    <BreakingBody className="hidden-xs">
                        {blockGroupSettings.hotnews_text}
                    </BreakingBody>
                    <BreakingRadius className="hidden-xs"
                        css={{ "$$background": "#039597" }}
                    />
                </CustomBreakingNews>
            )

            bottomLogo = (
                <BottomLogo 
                    css={blockGroupSettings.background_color ? {background : blockGroupSettings.background_color} : {}}
                >
                    <img src={window.innerWidth < 992 ? creditAgricoleBottomMobile : creditAgricoleBottomDesktop} />
                </BottomLogo>
            )
        }

        // if (customHotnewsUkraine === "1") {
        //     breakingNews = (
        //         <BreakingNews>
        //             <BreakingType>
        //                 {blockGroupSettings.hotnews_label}
        //             </BreakingType>
        //             <BreakingBody className="hidden-xs">
        //                 {blockGroupSettings.hotnews_text}
        //             </BreakingBody>
        //             <BreakingRadius className="hidden-xs" background="yellow" />
        //         </BreakingNews>
        //     )
        // }

        let partnerLogo = null;
        if (blockGroupSettings.partner_logo) {
            partnerLogo = (
                <PartnerLogo src={window.MEDIA_DOMAIN + '/' + blockGroupSettings.partner_logo.replace('.', ',0,50,0,0.')} />
            )
        }

        result.push((<Wrap
            data-section-id={sectionNumber}
            key={'blockgroup_' + sectionNumber}
            className={'hpe__block hpe__block--bizon ' + (partnerLogo ? ' hpe__block--with-partner' : null)}>
            {partnerLogo}
            <BlockWrapper
                className={`${getCustomThemeClassName(`categories-${blockGroupSettings.theme_color ? blockGroupSettings.theme_color : ""}`)}`}
                css={{
                    "$$backgroundColor": blockGroupSettings.background_color,
                    "$$borderColor": blockGroupSettings.border_color
                }}
                isDarkMode={props.isDarkMode}
                hasBackground={hasBackground}
                hasBorder={hasBorder}
                hasSectionName={hasSectionName}
                customHotnewsUkraine={customHotnewsUkraine}
                customOscars={customOscars}>
                {breakingNews}
                {blocks}
                {bottomLogo}
            </BlockWrapper>
        </Wrap>
        ));

        sectionNumber === 2 && result.push(<AdslotWithScrollWrapper key="_Billboard_2" name={adPrefix + "_Billboard_2" + adSuffix} />);
        sectionNumber === 5 && result.push(<AdslotWithScrollWrapper key="_Billboard_3" name={adPrefix + "_Billboard_3" + adSuffix} />);
        sectionNumber === 8 && result.push(<AdslotWithScrollWrapper key="_Billboard_4" name={adPrefix + "_Billboard_4" + adSuffix} />);
        sectionNumber === 11 && result.push(<AdslotWithScrollWrapper key="_Billboard_5" name={adPrefix + "_Billboard_5" + adSuffix} />);
        sectionNumber === 14 && result.push(<AdslotWithScrollWrapper key="_Billboard_6" name={adPrefix + "_Billboard_6" + adSuffix} />);

        // result.push(<Adslot name={adPrefix + "_after_section_" + sectionNumber} />);
        result.push(<div key={'blockgroup_' + sectionNumber + '_after'} id={'hp-after-' + sectionNumber + '-rows-placeholder'} className="hp-after-rows-placeholder" />);
    });

    return (
        <div className="hp">
            {result}
        </div>
    );
}

const Wrap = styled("div", {
    "@mobile": {
        marginTop: "33px"
    },
    "&.hpe__block--bizon": {
        "&.hpe__block--with-partner": {
            maxWidth: "1310px",
            margin: "0 auto"
        }
    }
});

const BlockWrapper = styled("div", {
    position: "realtive",
    variants: {
        "hasBackground": {
            true: {
                margin: "40px 0",
                padding: "40px 0 56px",
                background: "$$backgroundColor",
                "@mobile": {
                    padding: '33px 0 40px'
                }
            },
            false: {}
        },
        "hasBorder": {
            true: {
                maxWidth: "1296px",
                margin: "24px 0",
                padding: "24px 0",
                borderRadius: "10px",
                border: "2px solid",
                borderColor: "$$borderColor",
                "@media (min-width: 1200px)": {
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }
            },
            false: {}
        },
        "customHotnewsUkraine": {
            "1": {
                backgroundImage: `url("${flagUkraine}")`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                borderRadius: "4px"
            }
        },
        "customOscars": {
            "1": {
                backgroundImage: `url("${oscarsPCS}")`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                borderRadius: "4px"
            }
        },
        "isDarkMode": {
            true: {},
            false: {}
        }
    },
    compoundVariants: [
        {
            "hasBackground": true,
            "isDarkMode": true,
            css: {
                background: "$grayscale10"
            }
        }
    ]
});

const SectionName = styled("h2", {
    color: "#272424",
    fontSize: "28px",
    fontWeight: "lighter",
    background: "white",
    display: "inline-block",
    margin: "0",
    padding: "0 20px",
    position: "absolute",
    left: "40px",
    top: "-18px",
    whiteSpace: "nowrap",
    transition: "all 0.3s ease-in-out",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "94%",
    "@mobile": {
        left: "50%",
        transform: "translate(-50%, 0)",
        padding: "0 10px"
    },
    "@media (max-width: 359px)": {
        fontSize: "22px",
        top: "-14px",
        padding: "0 14px"
    }
});

const BreakingNews = styled("div", {
    backgroundColor: "$$background",
    display: "flex",
    justifyContent: "center",
    color: "#fff",
    padding: "18px 0",
    position: "relative",
    marginTop: "-24px",
    borderTopLeftRadius: "7px",
    borderTopRightRadius: "7px",
    transition: "all 0.3s ease-in-out",
    "@bp4": {
        justifyContent: "flex-start",
        padding: "20px 45px 20px"
    }
});
const BreakingType = styled("div", {
    display: "block",
    background: "#000",
    fontWeight: 500,
    whiteSpace: "nowrap",
    textAlign: "center",
    fontSize: "15px",
    padding: "0 5px"
});


const BreakingBody = styled("div", {
    display: "block",
    paddingLeft: "25px",
    lineHeight: "18px"
});
const BreakingRadius = styled("span", {
    background: "$$background",
    height: "6px",
    width: "100%",
    borderTopLeftRadius: "7px",
    borderTopRightRadius: "7px",
    position: "absolute",
    bottom: "-2px",
    left: "0",
    right: "0"
});

const PartnerLogo = styled("img", {
    display: "flex",
    marginLeft: "auto"
});

const CustomBreakingNews = styled(BreakingNews, {
    padding: "5px 0 0 0",
    justifyContent: "flex-start",
    [`& ${BreakingType}`]: {
        borderTopRightRadius: "7px",
        borderBottomRightRadius: "7px",
        fontSize: "17px",
        padding: "7px 19px 9px",
        marginLeft: "-1px",
        fontFamily: "Helvetica, sans-serif",
        marginTop: "97px",
        marginBottom: "12px"
    },
    "img": {
        position: "absolute",
        right: "0",
        left: "0",
        top: "-2px",
        margin: "auto",
        maxHeight: "100px",
        zIndex: 1
    },
    "@bp4": {
        padding: "5px 0 0 0",
        [`& ${BreakingType}`]: {
            marginLeft: "-2px",
            marginTop: "31px",
            marginBottom: "10px"
        }
    }
});
const CustomBreakingNewsUkraine = styled(BreakingNews, {
    width: "100%",
    height: "auto"
});
const CustomHotnewsText = styled("span", {
    color: "#039597",
    fontWeight: 600,
    span: {
        color: "#a0cc52",
        fontSize: "19px"
    }
});

const BottomLogo = styled("div", {
    background: "#fff",
    width: "100%",
    "img": {
        marginTop: "10px",
        maxHeight: "50px",
        display: "block",
        margin: "0 auto"
    }
});

export default withThemeContext(BlockList);
